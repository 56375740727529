.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.button-journey{
  background: none !important;
  color: #000 !important;
  border: none !important;
}
.btnLessonMapIcon{
  font-size: 35px;
  padding: 5px;
}
.btnLessonDotIcon{
  font-size: 20px;
  padding: 5px;
}
/* lesson journey */
.con-lesson-journey{
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  line-height: 0px;
  align-items: center;
  
}
.con-lesson-journey-bg{
  display: flex;
  flex-direction: row;
  background-color: black;
  border-radius: 20px;
  line-height: 40px;
  align-items: center;
  
}
.road-path{
  color:white;
  padding: 10px;
}
.line-draw{
  flex:1;
  height: 8px;
  background-color: white;
  
}
.line-draw-vertical-right{
  height: 200px;
  background-color: black;
  width: 40px;
  margin-top: -20px;
  margin-bottom: -40px;
  float: right;


}
.line-draw-vertical-left{
  height: 200px;
  background-color: black;
  width: 40px;
  margin-top: -20px;
  margin-bottom: -40px;
  float: left;


}
/* google pay button color effect */
.gpay-button.black {
  background-color: #1f3a9b !important;
  width: 80px !important;
  box-shadow: none;
}
.div-inline{
  display:inline-block;
  width: 70%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 375px) {
  .placeorder h5 {
    font-size: 15px;
  }
  .placeorder .col {
    font-size: 12px;
  }
 
}